import React from 'react';
import "./SaltybridgeSection12.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

export default function SaltybridegSection12(){
  const {t} = useTranslation()

    return (
    <div id="sbcom-orth1">
      <div className="container">
        <div className="rowCallToActionHolder"> 
          <div className="callToActionHolder">
            <h2 style={{"lineHeight": "1.25"}}>
              <strong>{t('sbcom_ss12_to_action_orth_text1')}</strong>
              <small>{t('sbcom_ss12_to_action_orth_text2a')}</small>
              <small>{t('sbcom_ss12_to_action_orth_text2b')}</small>
            </h2>
            <div className="sbcom-btn3-div">
              <a to="#" className="sbcom-btn3">{t('sbcom_ss12_to_action_orth_text3')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>)
}