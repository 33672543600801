import React from 'react';
import "./SaltybridegSection3ortho.scss"
import "../../../scss/components/saltybridge/heading.scss"
import { Img } from 'react-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import img_1 from '../../../images/ortho/userrev.png';
import img_2 from '../../../images/ortho/userrev.png';
import img_3 from '../../../images/ortho/userrev.png';
import img_4 from '../../../images/ortho/userrev.png';
import img_5 from '../../../images/ortho/userrev.png';


export default function SaltybridegSection3ortho(){
  const {t} = useTranslation()

    return  <div id="sbcom-testimonial-orth" className="sbcom-bg-section">
    <div className="container">
      <div className="row animate-box row-pb-md">
        <div className="col-md-8 col-md-offset-2 text-left sbcom-heading">
          <span style={{fontSize: "1rem"}}>{t('sbcom_ss3_ortho_text1')}</span>
          <h2><strong>{t('sbcom_ss3_ortho_text2')}</strong></h2>
          <p>{t('sbcom_ss3_ortho_text3')}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 animate-box">
          <div className="testimonial">
            <blockquote>
              <p>&ldquo;{t('sbcom_ss3_ortho_testimonial_text1a')}&rdquo;</p>
              <p className="author"> {/*<Img src={img_1} alt=""/> */}<cite>&mdash; {t('sbcom_ss3_ortho_testimonial_name_surname_text1a')}</cite></p>
            </blockquote>
          </div>

          <div className="testimonial sbcom-selected">
            <blockquote>
              <p>&ldquo;{t('sbcom_ss3_ortho_testimonial_text1d')}&rdquo;</p>
              <p className="author"> {/*<Img src={img_2} alt=""/> */}<cite>&mdash; {t('sbcom_ss3_ortho_testimonial_name_surname_text1d')}</cite></p>
            </blockquote>
          </div>
        </div>

        <div className="col-md-6 animate-box">
          <div className="testimonial sbcom-selected">
            <blockquote>
              <p>&ldquo;{t('sbcom_ss3_ortho_testimonial_text1c')}&rdquo;</p>
              <p className="author">{/*<Img src={img_3} alt=""/>*/} <cite>&mdash; {t('sbcom_ss3_ortho_testimonial_name_surname_text1c')}</cite></p>
            </blockquote>
          </div>

          <div className="testimonial">
            <blockquote>
              <p>&ldquo;{t('sbcom_ss3_ortho_testimonial_text1b')}&rdquo;</p>
              <p className="author">{/*<Img src={img_4} alt=""/>*/} <cite>&mdash; {t('sbcom_ss3_ortho_testimonial_name_surname_text1b')}</cite></p>
            </blockquote>
          </div>

          <div className="testimonial">
            <blockquote>
              <p>&ldquo;{t('sbcom_ss3_ortho_testimonial_text1e')}&rdquo;</p>
              <p className="author"> {/*<Img src={img_5} alt=""/>*/} <cite>&mdash; {t('sbcom_ss3_ortho_testimonial_name_surname_text1e')}</cite></p>
            </blockquote>
          </div>

        </div>
      </div>
    </div>
  </div>

}