import React from 'react';
import "./SaltybridgeSection15.scss"
import "../../../scss/components/saltybridge/heading.scss"

import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const IframeOrthjankovicMap = () => {

  return( 
    <iframe
      className="map" 
      width="100%" 
      height="100%"
      stylename="border:0" 
      loading="lazy"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      allowFullScreen 
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJu7NvoHRwWkcR0ijTJhBbgUI&key=AIzaSyAMhz8lCqEGCf1UHCR88ArOAahPAMMT6UA">
    </iframe>
  );
};

export default function SaltybridegSection15(){
  const {t} = useTranslation()

    return (
    <div id="sbcom-orth4">
      <div className="container">

        <div className="sb-div-map">
          <div className="div-1">
            <div className="c-1">
              <h1 className="special-title" style={{textAlign: "center"}}>
                <strong>{t('sbcom_ss15_map_orth_text1')}</strong>
              </h1>
              <span className="s"></span>
            </div>
          </div>
          <div className="div-2">
            <div className="c-2">
              <div className="cc-2-1">
                <IframeOrthjankovicMap/>
              </div>
              <div style={{height: "2rem",minHeight: "6rem"}}></div>
              <div className="cc-2-2">
                <p className="address" style={{textAlign: "center"}}>{t('sbcom_ss15_map_orth_text2')}</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>)
}