import React from 'react';
import "./SaltybridgeSection16.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

import img_1 from '../../../images/associates/american-association-of-orthodondists-logo-grey.png';
import img_2 from '../../../images/associates/EOS-grey.png';
import img_3 from '../../../images/associates/invisalign-provider-logo-charcoal-en-png.png';
import img_4 from '../../../images/associates/WFO_Logo-b-grey-removebg-preview.png';

export default function SaltybridegSection16(){
  const {t} = useTranslation()

    return (
<div id="sbcom-orth5">
  <div className="container">
      <div className="row"> 
            <div className="column" /*style={{marginLeft: "8.33333333%"}}*/ >
              <a href="https://www.aaoinfo.org/" target="_blank" rel="noopener noreferrer"><Img className="img" src={img_1} /></a>
            </div>
            <div className="column">
              <a href="http://www.eoseurope.org/" target="_blank" rel="noopener noreferrer"><Img className="img" src={img_2} /></a>
            </div>
            <div className="column">
              <a href="https://www.invisalign.eu/" target="_blank" rel="noopener noreferrer"><Img className="img" src={img_3} /></a>
            </div>
            <div className="column">
              <a href="https://www.wfo.org/" target="_blank" rel="noopener noreferrer"><Img className="img" src={img_4} /></a>
            </div>
      </div>
  </div>
</div>)
}