import React from 'react'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby';

import SaltybridgeLoader from '../components/sb/SaltybridgeLoader'
import SaltybridgeTopMenu from '../components/sb/SaltybridgeTopMenu'

import SaltybridegSection12 from '../components/sb/SaltybridegSection12'
import SaltybridegSection13 from '../components/sb/SaltybridegSection13'
import SaltybridegSection11 from '../components/sb/SaltybridegSection11'
import SaltybridegSection25 from '../components/sb/SaltybridegSection25'
import SaltybridegSection15 from '../components/sb/SaltybridegSection15'
import SaltybridegSection16 from '../components/sb/SaltybridegSection16'
import SaltybridegSection17 from '../components/sb/SaltybridegSection17'
import SaltybridegSection3ortho from '../components/sb/SaltybridegSection3ortho'

import img_0 from '../images/ortho/img_bg_1.jpg'
//import img_1 from '../images/ortho/MD_06526.jpg';
import img_1 from '../images/ortho/ulaz.jpg';
import img_2 from '../images/ortho/MD_06553.jpg';

import { SEO } from "../components/SEO/SEO"

import GoToTopButton from '../components/GoToTop'

class Home extends React.Component {
  constructor (props){
    super(props);
    this.topMenuRef = React.createRef()
    this.scrollTo = this.scrollTo.bind(this)
  }
  
  scrollTo() {
    if (!this.topMenuRef.current) return;
    this.topMenuRef.current.scrollIntoView({ behavior: "smooth", block:"center"});
  }

  render() {
    const { location } = this.props;
    const putanja = location.pathname;

    const t = this.props.t
    const s13_props = { text1: t('sbcom_ss13_about_orth_text1'),
            text2: t('sbcom_ss13_about_orth_text2'),
            text3: t('sbcom_ss13_about_orth_text3'),
            text4: t('sbcom_ss13_about_orth_text4'),
            text5a: t('sbcom_ss13_about_orth_text5a'),
            text5b: t('sbcom_ss13_about_orth_text5b'),
            text5c: t('sbcom_ss13_about_orth_text5c'),
            text5d: t('sbcom_ss13_about_orth_text5d'),
            text5e: t('sbcom_ss13_about_orth_text5e'),
            text5f: t('sbcom_ss13_about_orth_text5f'),
            text5g: t('sbcom_ss13_about_orth_text5g'),
            text6a: t('sbcom_ss13_about_orth_text6a'),
            text6b: t('sbcom_ss13_about_orth_text6b'),
            text6c: t('sbcom_ss13_about_orth_text6c'),
            text7a: t('sbcom_ss13_about_orth_text7a'),
            text7b: t('sbcom_ss13_about_orth_text7b'),
            link1: t('3pweb.link1'),
            link2: t('3pweb.link3'),
            link3: t('3pweb.link2'),
            link4: t('3pweb.invisalign_smile_link')
        }
    const class_name_dif_lang_prop = t.language === 'en' ?  'english-class' : 'serbian-class'

    return (
      <div className="Main">
        <SaltybridgeLoader/>

        <div id="page">
          <SaltybridgeTopMenu pathname={putanja} onContactUsClick={this.scrollTo}/>
          <Header image={img_0}/>
          <div style={{"marginTop": "-15rem"}}>
            <SaltybridegSection12/>
            <SaltybridegSection13 image1={img_1} image2={img_2} content={s13_props} classNameDifLang={class_name_dif_lang_prop}/>
            {/*<SaltybridegSection14/>*/}
            <SaltybridegSection25/>
            <SaltybridegSection17/>
            <SaltybridegSection3ortho/>
            <SaltybridegSection15/>  
            <SaltybridegSection16/>
            <SaltybridegSection11  ref={this.topMenuRef}/>
          </div>
          <Footer/>
        </div>

        <GoToTopButton></GoToTopButton>
      </div>
    );
  }
}

export default withTranslation()(Home);

/*export const Head = () => (
  <>
    <title>Hello World</title>
    <meta name="description" content="Hello World" />
  </>
)*/

/* KADA PROVALIS KAKO SE RADI SEO BICE SUPER SREDITI GA XD*/
export const Head = () => (
  <SEO />
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;