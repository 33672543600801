import React from 'react';
import "./SaltybridgeSection13.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'
import { Link, Link as NavLink } from "gatsby"

export default function SaltybridegSection13(props){
  const {image1, image2, content, classNameDifLang} = props

    return (
    <div id="sbcom-orth2">
      <div className="container lg">

        <div className="sbcom-left-div-holder">
          <div className="holder-left">
            <div className="sbcom-image-holder1 sbcom-offset-dots-white">
              <Img className="img1" src={image1}></Img>
            </div>
            <div className="sbcom-image-holder2">
              <Img className="img2" src={image2}></Img>
            </div>
          </div>
        </div>

        <div className="sbcom-right-dev-holder">
          <div className="holder-right">
              <div className="text1">
                <h1>{content.text1}<strong className={classNameDifLang}>{content.text2}</strong>{content.text3}<strong>{content.text4}</strong>
                </h1>
              </div>
              <div className="text2">
                <p className="description1">
                <b>{content.text5a}</b>
                {content.text5b}
                <NavLink className="act-text-link" target="_blank" rel="noopener noreferrer" href={content.link1}><b>{content.text5c}</b></NavLink>
                <NavLink className="act-text-link" target="_blank" rel="noopener noreferrer" href={content.link2}><b>{content.text5d}</b></NavLink>
                {content.text5e}
                <NavLink className="act-text-link" target="_blank" rel="noopener noreferrer" href={content.link3}><b>{content.text5f}</b></NavLink>
                {content.text5g}
                {content.text6a}
                <NavLink className="act-text-link" target="_blank" rel="noopener noreferrer" href={content.link4}><b>{content.text6b}</b></NavLink>
                {content.text6c}
                {content.text7a}
                <NavLink className="act-text-link" href="/invisalign"><b>{content.text7b}</b></NavLink>
                </p>
              </div>
              {/*<div className="text3">
                <p className="description2">
                {content.text0}
                </p>
            </div>*/}
          </div>
        </div>
        
      </div>
    </div>)
}