import React from 'react';
import "./SaltybridgeSection17.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

import img_1 from '../../../images/ortho/MD_06524.jpg';

export default function SaltybridegSection17(){
  const {t} = useTranslation()

    return (
<div id="sbcom-orth6">
  <div className="container">
      <div className="content-padding">
        <div className="div1">
          <div className="div1a">
            <Img className="img1" src={img_1}/>
          </div>
        </div>
        <div className="div2">
          <div className="div2a">
            <div className="hm1" style={{width: "13rem", height: "13rem", top: "17.5%", opacity: "0.75"}}></div>
              <div className="hm2" style={{width: "4rem", height: "4rem", top: "0", left: "100%"}}></div>
              <div className="hm3">
                <h1 className="s1"><strong>{t('sbcom_ss17_associates_orth_text1')}</strong></h1>
              </div>
              <div className="hm4">
                <p className="s2">{t('sbcom_ss17_associates_orth_text2')}</p>
              </div>
              <div className="hm5">
                <p className="s2">{t('sbcom_ss17_associates_orth_text3')}</p>
                <p className="s2">{t('sbcom_ss17_associates_orth_text4a')}<br/><b>{t('sbcom_ss17_associates_orth_text4b')}</b><br/><b>{t('sbcom_ss17_associates_orth_text4c')}</b><br/><b>{t('sbcom_ss17_associates_orth_text4d')}</b>{t('sbcom_ss17_associates_orth_text4e')}</p>
                <p className="s2">{t('sbcom_ss17_associates_orth_text5')}</p>
              </div>
              <div className="hm6" style={{width: "4rem", height: "4rem", right: "90%", bottom: "0"}}></div>
            </div>
          </div>
        </div>       
    </div>
</div>)
}