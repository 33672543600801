import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next'
import './Header.scss';

const Header = (props) =>{ 
  const {t} = useTranslation()
  const name = props.name
  const image = props.image

  return (
  <header id="sbcom-header" className="sbcom-cover" role="banner" 
  style={{backgroundImage:`url(${image})`}}
  data-stellar-background-ratio="0.5">
    {/* ovo radi <div className="overlay"></div>*/}
    <div className="container">
      <div className="row">
        <div className="col-md-7 text-left">
          <div className="display-t">
            <div className="display-tc animate-box" data-animate-effect="fadeInUp">
              <h3 className="mb30">{t('sbcom_header_orth_text0')}</h3>
              <h1 className="mb30">{t('sbcom_header_orth_text1')}</h1>
              <p>
                <a href="/orthodontics" target="_blank" className="btn btn-primary">{t('sbcom_header_orth_text2')}</a>   {/*<em className="or">{t('sbcom_header_orth_text3')}</em>*/} 
                {/*<a href={t('3pweb.vimeo')} className="link-watch popup-vimeo">{t('sbcom_header_orth_text4')}</a>*/}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
