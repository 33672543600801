import React from 'react';
import "./SaltybridgeSection25.scss"
import "../../../scss/components/saltybridge/heading.scss"

import { Img } from 'react-image'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
//import { Link, NavLink } from "react-router-dom"
import { Link, Link as NavLink } from "gatsby"

import img_1 from '../../../images/ortho/s1.jpg';
import img_2 from '../../../images/ortho/s2.jpg';
import img_3 from '../../../images/ortho/s3.jpg';

const PrettyFaceComponent = function (props){
  return <div className={"sb2-div "+props.className}>
  <div className="sb2-div0">
    <Img className="sb-img" src={props.image}></Img>
    <div className="sb-person-info">
      <div className="sb-person-title">
        <small>{props.headline}</small>{props.clientName}
      </div>
      <div className="sb-person-content">{props.text}
        <p className="sb-para" style={{ textAlign: "center" }}>
          <NavLink className="sb-btlink" to="/gallery">{props.dugmeText} <i    style={{ color: 'rgb(9, 99, 155)'}} className="icon-play"/></NavLink>
        </p>
      </div>
    </div>
  </div>
</div>;
}

export default function SaltybridegSection25() {
  const { t } = useTranslation()

  return (
    <div id="sbcom-orth14">
      <div className="container">

        <div className="sb1">
          <div className="sb1-div">
            <div className="sb1-div0">
              <div className="sb1-div0-1"><h2><strong>{t('sbcom_ss25_testimonial_orth_text1')}</strong></h2></div>
              <div className="sb1-div0-2"><span></span></div>
              <div className="sb1-div0-3"><a className="sb-btn" href="/gallery" title={t('sbcom_ss25_testimonial_orth_text2')}>{t('sbcom_ss25_testimonial_orth_text2')}</a></div>
              <div className="sb1-div0-4"><span></span></div>
            </div>
          </div>
        </div>


        <div className="sb2">
          <PrettyFaceComponent image={img_1} className="c1" dugmeText={t('sbcom_ss25_testimonial_orth_text3')}/>
          <PrettyFaceComponent image={img_2} className="c2" dugmeText={t('sbcom_ss25_testimonial_orth_text3')}/>
          <PrettyFaceComponent image={img_3} className="c3" dugmeText={t('sbcom_ss25_testimonial_orth_text3')}/>
        </div>
      </div>
    </div>)
}